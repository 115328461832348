const EceEventData = [
    {
        event_id: 21,
        name: 'Project Exhibition',
        description: 'Explore a showcase of creativity and indeguinity at our project exhibition.',
        event_type: 'Exhibition',
        fee: 'FREE',
        student1: 'Vaibhav S',
        s1_phone: 9074653998,
        student2: 'Gopika Sanil',
        s2_phone: 9446060137,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: true,
        venue: 'Analog Electronics Lab',
        time: '9:30 AM-4:10 PM'
    },
    {
        event_id: 22,
        name: 'Project Competition',
        description: 'Creativity meets technical excellence for a chance to shine.',
        event_type: 'Competiton',
        fee: 'FREE',
        student1: 'Sandhwana Das',
        s1_phone: 9048577783,
        student2: 'Aswin Diwakaran',
        s2_phone: 9495175946,
        staff: '',
        prize: true,
        dept: 'ece',
        reg: false,
        group: true,
        venue: 'Analog Electronics Lab',
        time: '9:30 AM-2:00 PM'

    },
    {
        event_id: 23,
        name: 'Hackathon',
        description: 'Join hackathon game and unleash coding prowess in a race against time.',
        event_type: 'Games',
        fee: 'FREE',
        student1: 'Abhinav Sujith',
        s1_phone: 9895697076,
        student2: 'Ahammed Sinan Muhammed',
        s2_phone: 8590724501,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: true,
        venue: 'Advanced Communication Lab',
        time: '9:00 AM-10:30 PM'
    },
    {
        event_id: 24,
        name: 'Robot Racing',
        description: 'Robots navigates sharp turns and straight aways reaching incredible speeds.Students compete with time.',
        event_type: 'Games',
        fee: 30,
        student1: 'Akash Benny',
        s1_phone: 6238841019,
        student2: 'Binil Kurian',
        s2_phone: 8590902911,
        staff: '',
        prize: true,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Ground Floor, EC Block',
        time: '9:00 AM-4:00 PM'
    },
    {
        event_id: 25,
        name: 'Treasure Hunt',
        description: 'It is a interactive and engaging activity where participants follows a series of clues or puzzle to discover a hidden tressure.',
        event_type: 'Games',
        fee: 50,
        student1: 'Anusree C',
        s1_phone: 7510222442,
        student2: 'Ashish Mathew',
        s2_phone: 9778376698,
        staff: '',
        prize: true,
        dept: 'ece',
        reg: false,
        group: true,
        venue: 'Research Lab',
        time: '10:00 AM-1:00 PM'
    },
    {
        event_id: 26,
        name: 'Circuit Designing',
        description: 'Players engage in circuit design challenges, creating electronic circuit and solving puzzles while being provided with circuit diagrams as a guide.',
        event_type: 'Games',
        fee: 'FREE',
        student1: 'Pranav N',
        s1_phone: 7736771470,
        student2: 'C P Varsha',
        s2_phone: 7025734216,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Analog Integrated Lab',
        time: '10:00 AM-1:00 PM'
    },
    {
        event_id: 27,
        name: 'Junkyard Wars',
        description: 'Puzzles-shmuzzles are meant to be solved, so what are you waiting for? Lets get solving you all.',
        event_type: 'Games',
        fee: 'FREE',
        student1: 'Ajimon Francis',
        s1_phone: 9446333100,
        student2: 'Kiran K',
        s2_phone: 7034534269,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: true,
        venue: 'Logic Circuit Design Lab',
        time: '11:30 AM-1:00 PM'
    },
    {
        event_id: 28,
        name: 'Crossword games',
        description: 'Embark on a celebral adventure with crossword games, where words weave a tapestry off knowledge and wit.',
        event_type: 'Games',
        fee: 'FREE',
        student1: 'Ksheera sajeesh',
        s1_phone: 8943382841,
        student2: 'Nibin B V',
        s2_phone: 8848829894,
        staff: '',
        prize: true,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Tutorial Room (UG)',
        time: '2:00 PM-4:00 PM'
    },
    {
        event_id: 29,
        name: 'Blank coding',
        description: 'It is a challenging task that typically involves writing code in a text editor or IDE without seeing the screen.',
        event_type: 'Games',
        fee: 'FREE',
        student1: 'Alaida Thomas',
        s1_phone: 7907830464,
        student2: 'Sreejishnu',
        s2_phone: 9188865478,
        staff: '',
        prize: true,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Research Lab',
        time: '9:30 AM-11:00 AM'
    },
    {
        event_id: 30,
        name: 'Arcade',
        description: 'Chess, Hand and Toes, Memory game, Balance me( nut ), Pyramid by glass, Filp cross xos, Bullseye, Efootball, Buzzwire, Penalty shootout, Cube solving',
        event_type: 'Games',
        fee: 20,
        student1: 'Bobit',
        s1_phone: 8547883214,
        student2: 'Sanjay',
        s2_phone: 8078824706,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: true,
        venue: 'Ground Floor, EC Block',
        time: '9:30 AM-4:10 PM'
    },
    {
        event_id: 31,
        name: 'Idea explorer',
        description: 'Idea exploration is like embarking on a journey through the landscape of creativity. It involves delving into the vast realms of possibilities, connecting disparate thoughts, and uncovering novel concepts.',
        event_type: 'Competitions',
        fee: 'FREE',
        student1: 'Navya M',
        s1_phone: 9037368370,
        student2: 'Melwin Paul',
        s2_phone: 9207065246,
        staff: '',
        prize: true,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Advanced Communication Lab',
        time: '2:00 PM-4:00 PM'
    },
    {
        event_id: 32,
        name: 'Circuit Debbugging',
        description: 'Embark on a journey of discovery and challenge as you delve into the fascinating world of circuit debugging! Unravel the mysteries hidden within the pathways of electrons. Navigate through challenges, decode signals, and triumph over technical puzzles.',
        event_type: 'Competitions',
        fee: 'FREE',
        student1: 'Amruthendu K',
        s1_phone: 9778121413,
        student2: 'Devika Dinesh',
        s2_phone: 8089166527,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Communication Engineering Lab',
        time: '11:30 AM-12:30 PM'
    },
    {
        event_id: 33,
        name: 'Quiz',
        description: 'Challenge yourself with queries that delve into the heart of electronic circuits, components, and theories.',
        event_type: 'Competitions',
        fee: 10,
        student1: 'Anulakshmi C',
        s1_phone: 8714240804,
        student2: 'Aparna K',
        s2_phone: 6282949096,
        staff: '',
        prize: true,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Advanced Communication Lab',
        time: '11:00 AM-1:00 PM'
    },
    {
        event_id: 34,
        name: 'Marketing',
        description: 'Ignite the brand\'s journey to success with a meticulously crafted marketing masterpiece! We weave the narrative of your uniqueness, casting a captivating spell on your audience.',
        event_type: 'Competitions',
        fee: 'FREE',
        student1: 'Alex Daniel',
        s1_phone: 8590923638,
        student2: 'Krishnapriya V S',
        s2_phone: 8590759927,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Intel Galileo Lab',
        time: '9:30 AM-12:00 PM'
    },
    {
        event_id: 35,
        name: 'Photography/Photo manupulation',
        description: 'Capture the essence of life through your lens! Join our photography competition and unleash your creativity.',
        event_type: 'Competitions',
        fee: 'FREE',
        student1: 'Ashish Mathew',
        s1_phone: 9778376698,
        student2: 'Thomas George',
        s2_phone: 8590016559,
        staff: '',
        prize: true,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Ground Floor, EC Block',
        time: '9:30 AM-12:00 PM'
    },
    {
        event_id: 36,
        name: 'Soldering',
        description: 'Soldering is like melting tiny metal bits to stick things together.',
        event_type: 'Competitions',
        fee: 'FREE',
        student1: 'Sreerag K P',
        s1_phone: 8330057694,
        student2: 'Abhinav M',
        s2_phone: 9400379330,
        staff: '',
        prize: true,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Communication Engineering Lab',
        time: '9:30 AM-11:00 AM'
    },
    {
        event_id: 37,
        name: 'Logic Circuit Design',
        description: 'Dive into the intricate world of logic circuits, where binary decisions come to life in a symphony of electronic pulses.',
        event_type: 'Competitions',
        fee: 'FREE',
        student1: 'Vishnupriya',
        s1_phone: 8157817792,
        student2: ' Dishna',
        s2_phone: 8848537445,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'Logic Circuit Design Lab',
        time: '9:30 AM-11:00 AM'
    },
    {
        event_id: 42,
        name: 'Electronics Room',
        description: 'Explore a the latest in electonic innovations at our electronics room events featuring interacttive exhibits and cutting edge technology demonstrations for enthusiaists of all levels',
        event_type: 'ElectroComm Room',
        fee: 'FREE',
        student1: 'Abinav V M',
        s1_phone: 8943405546,
        student2: 'Anusha Antony',
        s2_phone: 8921507439,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'S3 ECE Block',
        time: '9:30 AM-4:10  PM'
    },
    {
        event_id: 38,
        name: 'Melinoe: The Journey to moon',
        description: 'Step into a realm where the art of communication takes center stage—welcome to "Connections Unleashed: The Communication Room Experience." This unique event is a celebration of the diverse ways we connect, collaborate, and communicate in our ever-evolving world.',
        event_type: 'ElectroComm Room',
        fee: 'FREE',
        student1: 'Jessay Jose',
        s1_phone: 8129237881,
        student2: 'Angel Mary',
        s2_phone: 8075814046,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'S5 ECE Block',
        time: '9:30 AM-4:10 PM'
    },
    {
        event_id: 39,
        name: 'Talent Expo',
        description: 'Explore a showcase of talents andd creativity.',
        event_type: 'Exhibition',
        fee: 'FREE',
        student1: 'Ansha antony',
        s1_phone: 8921507439,
        student2: '',
        s2_phone: 0,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'S3 ECE',
        time: '9:30 AM-4:10 PM'
    },
    {
        event_id: 40,
        name: 'Room of Mystery',
        description: 'Step into a world of intrigue and suspense as you enter the Room of Mystery—an immersive experience designed to challenge your wits, spark your curiosity, and transport you to a realm where the unknown beckons.',
        event_type: 'MysteryRoom',
        fee: 10,
        student1: 'Alan P Mathew',
        s1_phone: 9495066565,
        student2: 'Ajay Binu',
        s2_phone: 8330071700,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'F5 ECE Block',
        time: '9:30 AM-4:10 PM'
    },
    {
        event_id: 41,
        name: 'VR Gaming',
        description: 'Step into a new dimension with a virtual reality event!! Join us for an immersive experiencethat transcends reality.',
        event_type: 'Gaming',
        fee: 60,
        student1: 'Abhinaya',
        s1_phone: 9605610154,
        student2: 'Sanju P S',
        s2_phone: 9744221445,
        staff: '',
        prize: false,
        dept: 'ece',
        reg: false,
        group: false,
        venue: 'S7 ECE',
        time: '9:30 AM-4:10 PM'
    },
];

export default EceEventData;