const CeEventData = [
    {
        event_id: 61,
        name: 'TAJ MAHAL',
        description: 'The Taj Mahal, a UNESCO World Heritage site in Agra, India, is an exquisite marble mausoleum commissioned by Mughal Emperor Shah Jahan in memory of his beloved wife Mumtaz Mahal',
        event_type: 'Model',
        fee: 'FREE',
        student1: 'Abhinav M K',
        s1_phone: 6238930704,
        student2: 'Vidhu Krishna ',
        s2_phone: 9400878285,
        staff: 'Mr. Midhun Krishnan ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: true,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 62,
        name: 'OPERA HOUSE',
        description: 'The Sydney Opera House, a globally recognized architectural masterpiece, graces the shores of Sydney Harbor with its iconic sail-like structures. Designed by Jørn Utzon, it stands as a symbol of Australias cultural prowess and a premier venue for performing arts. ',
        event_type: 'Model',
        fee: 'FREE',
        student1: 'Anubin T Mani',
        s1_phone: 7907855643,
        student2: 'Armage Don',
        s2_phone: 8547531341,
        staff: 'Mr. Logi N Boby',
        prize: true,
        dept: 'ce',
        reg: false,
        group: true,
        venue: 'Auditorium',
        time: 'Full Day'

    },
    {
        event_id: 63,
        name: 'METRO',
        description: 'The metro is an urban rapid transit system typically characterized by underground tunnels and elevated tracks, providing a convenient and efficient means of public transportation in densely populated areas. It often consists of trains that run at high frequencies, connecting various stations across a city',
        event_type: 'Model',
        fee: 'FREE',
        student1: 'Sandra Chandran',
        s1_phone: 9778177547,
        student2: 'Sreenanda Santhosh',
        s2_phone: 7736990316,
        staff: 'Ms. Anitta Jose ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 64,
        name: 'MOON',
        description: 'Showing the real looking 2D model of moon',
        event_type: 'Model',
        fee: 'FREE',
        student1: 'Litwin Xavier ',
        s1_phone: 7594824242,
        student2: 'Ajay John',
        s2_phone: 9633108531,
        staff: 'Mr. Saneesh K ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 76,
        name: '3D Modelling',
        description: 'Crafting realities in the world of 3D Modeling, Exhibiting 3d printing devices that creates a physical object from a digital design.',
        event_type: 'Exhibition',
        fee: 'FREE',
        student1: 'Archana K',
        s1_phone: 9074796631,
        student2: 'Aparna Mohanan',
        s2_phone: 9946160084,
        staff: '',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 65,
        name: 'ONE PIECE',
        description: 'Embark on an exhilarating adventure with our treasure hunt—an immersive quest that blends cunning clues and hidden surprises. Unleash your inner explorer and unravel the mysteries to discover the ultimate prize, making every step a thrilling journey towards treasure!',
        event_type: 'TREASURE HUNT',
        fee: 100,
        student1: 'Vishnu Das',
        s1_phone: 9562483676,
        student2: 'Teena Vinod',
        s2_phone: 8111943284,
        staff: 'Ms. Aswathi ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: '11:00 AM-1:00 PM'
    },
    {
        event_id: 66,
        name: 'MIND MAZE',
        description: 'Unleash Your Knowledge in the Ultimate Quiz Quest! ',
        event_type: 'QUIZ',
        fee: 'FREE',
        student1: 'Hrithuvarna Anil',
        s1_phone: 7012555876,
        student2: 'Siddarth D',
        s2_phone: 8304903083,
        staff: 'Ms. Hridya P  ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'S7 CE-B',
        time: '10:00 AM-11:00 AM'
    },
    {
        event_id: 67,
        name: 'POPSICLE PONTE',
        description: 'Participants had to bring out a best building a bridge with popsicles. The bridge that took maximum is declared as best ',
        event_type: 'BRIDGE MAKING',
        fee: 'FREE',
        student1: 'Abhijith Surendran',
        s1_phone: 9895757464,
        student2: 'Anjali Mathew',
        s2_phone: 7558023654,
        staff: 'Ms. Athira Rajendran  ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: '02:00 PM-03:00 PM'
    },
    {
        event_id: 68,
        name: 'RING MASTER',
        description: 'Aim, Swing, and Score in the Ultimate Throwing Ring Extravaganza! ',
        event_type: 'GAME',
        fee: 30,
        student1: 'Amith N ',
        s1_phone: 6282937918,
        student2: 'Athul raj',
        s2_phone: 7034837834,
        staff: 'Fr. Tony ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 69,
        name: 'SHATTER STRIKE',
        description: 'Precision Ball Throwing for a Glass-Shattering Thrill!',
        event_type: 'GAME',
        fee: 20,
        student1: 'Abhay K P',
        s1_phone: 7994876390,
        student2: 'Abhay K P',
        s2_phone: 9037105248,
        staff: 'Ms. Anitha Babu ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 70,
        name: 'PYRAMID',
        description: 'Challenge your spatial skills and precision in our Glass Pyramid Stacking Game! Strategically arrange glasses to construct a sturdy pyramid,testing your dexterity and planning as you aim to create the perfect glass structure',
        event_type: 'GAME',
        fee: 20,
        student1: 'Akshath',
        s1_phone: 9188452491,
        student2: 'Amain P',
        s2_phone: 9207671151,
        staff: 'Ms. Sinai Michel ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 71,
        name: 'WICKET WHIZ',
        description: 'Precision Throws in the Ultimate Wicket Challenge!',
        event_type: 'GAME',
        fee: 20,
        student1: 'Yuvaraj',
        s1_phone: 9778032228,
        student2: 'Gokul',
        s2_phone: 9207671151,
        staff: 'Ms. Anuragi ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 72,
        name: 'GAMILTIA ',
        description: 'Mini Miltia game played by solo and squad. Entry: solo:50 Squad: 100',
        event_type: 'GAME',
        fee: 50,
        student1: 'Athira Ajith',
        s1_phone: 8139042484,
        student2: 'Kirandev ',
        s2_phone: 9747547366,
        staff: 'Ms. Resmitha Rani Antony ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 73,
        name: 'SPOT KICK FRENZY',
        description: 'Score or Save, Every Penalty Counts ',
        event_type: 'GAME',
        fee: 20,
        student1: 'Abhijith M',
        s1_phone: 8301017921,
        student2: 'Hridya K',
        s2_phone: 7902314443,
        staff: 'Mr. Logi N Boby  ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 74,
        name: 'BAD BASKET',
        description: 'Splayers glide across the court, their movements a symphony of skill and strategy. The thud of the ball,the squeak of sneakers on hardwood – basketball, where every dribble and slam dunk creates a heartbeat for fans and athletes alike, uniting them in the electrifying embrace of the sport ',
        event_type: 'GAME',
        fee: 20,
        student1: 'Surya Sankar',
        s1_phone: 6282290226,
        student2: 'Veni K ',
        s2_phone: 6282656535,
        staff: 'Mr. Midhun Krishnan  ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },
    {
        event_id: 75,
        name: 'DEAD HANG',
        description: 'In the simplicity of a deadhang, find strength suspended in time. Gripping the bar, muscles engaged, its a silent challenge that transforms endurance into a powerful display of resilience and determination. ',
        event_type: 'GAME',
        fee: 20,
        student1: 'Anjana',
        s1_phone: 9037949421,
        student2: 'Sanjana',
        s2_phone: 9778159044,
        staff: 'Mr. Saneesh K  ',
        prize: true,
        dept: 'ce',
        reg: false,
        group: false,
        venue: 'Auditorium',
        time: 'Full Day'
    },

];
export default CeEventData;