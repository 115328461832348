import Qrad from "../../assets/qr/HoDAD.png";
import Qrcs from "../../assets/qr/HoDCS.png";
import Qraei from "../../assets/qr/HoDEI.png";
import Qrcsd from "../../assets/qr/HoDCSD.png";
import Qreee from "../../assets/qr/HoDEEE.png";
import Qrec from "../../assets/qr/HoDEC.png";
import Qrme from "../../assets/qr/HoDME.png";
import Qrce from "../../assets/qr/HoDCE.png";
const AccountsData = {
    cse: {
        name: 'HOD Computer Science & Engineering',
        accountNo: '0078053000079580',
        ifsc: 'SIBL0000078',
        bankName: 'Bank South Indian bank, Thalassery',
        Qr: Qrcs,
    },
    aei: {
        name: 'HOD Applied Electronics & Instrumentation',
        accountNo: '0078053000079579',
        ifsc: 'SIBL0000078',
        bankName: 'Bank South Indian bank, Thalassery',
        Qr: Qraei,
    },
    me: {
        name: 'HOD Mechanical Engineering',
        accountNo: '0078053000079596',
        ifsc: 'SIBL0000078',
        bankName: 'Bank South Indian bank, Thalassery',
        Qr: Qrme,
    },
    ece: {
        name: 'HOD Electronics & Communication ',
        accountNo: '0078053000079583',
        ifsc: 'SIBL0000078',
        Qr: Qrec,
    },
    csd: {
        name: 'HOD Computer Science & Design',
        accountNo: '0078053000079581',
        ifsc: 'SIBL0000078',
        bankName: 'Bank South Indian bank, Thalassery',
        Qr: Qrcsd,
    },
    ce: {
        name: 'HOD Civil Engineering',
        accountNo: '0078053000079641',
        ifsc: 'SIBL0000078',
        bankName: 'Bank South Indian bank, Thalassery',
        Qr: Qrce,
    },
    eee: {
        name: 'HOD Electrical & Electronics Engineering',
        accountNo: '0078053000079640',
        ifsc: 'SIBL0000078',
        bankName: 'Bank South Indian bank, Thalassery',
        Qr: Qreee,
    },
    ads: {

        name: 'HOD Artificial Intelligence',
        accountNo: '0078053000079585',
        ifsc: 'SIBL0000078',
        bankName: 'Bank South Indian bank, Thalassery',
        Qr : Qrad,
    },
    csbs: {
        name: 'HOD Computer Science & Engineering',
        accountNo: '0078053000079580',
        ifsc: 'SIBL0000078',
        bankName: 'Bank South Indian bank, Thalassery',
        Qr: Qrcs,
    },
    cscy: {
        name: 'HOD Computer Science & Engineering',
        accountNo: '0078053000079580',
        ifsc: 'SIBL0000078',
        bankName: 'Bank South Indian bank, Thalassery',
        Qr: Qrcs,
    }
    // comm:{
    //     name: '',
    //     accountNo: '',
    //     ifsc: '',
    //     bankName: '',
    // }
};

export default AccountsData;
