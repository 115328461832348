const AdsEventData = [
    {
        event_id:152,
        name: 'Code hunters ',
        description: 'The Epic Quest for Hidden Treasures are here. Solve the Aptitude questions to obtain the clues. Use your skills to decode the clues and Reach the Destinations. So     Are you ready for this task.  Group(4 members)',
        event_type: 'Treasure hunt ',
        fee: 30,
        student1: ' Nixon ',
        s1_phone: 7510275707,
        student2: 'Alfin',
        s2_phone: 8157804064,
        staff: 'Ms.Soumya Thomas ',
        prize: true,
        dept:'ads',
        reg: false,
        group: true,
        venue: 'S3 ADS',
        time: '11:30-2:00'
    },
    {
        event_id:141,
        name: 'GNIDOC',
        description: 'Participants have to find solution for a given problem.Programming language will be either Python or C.',
        event_type: 'Coding',
        fee: 'FREE',
        student1: 'Austine S Manuel',
        s1_phone: 9460388733,
        student2: 'Adwaith',
        s2_phone: 7736575578,
        staff: 'Ms.Jijina MT',
        prize: true,
        dept:'ads',
        reg: false,
        group: false,
        venue: 'Hardware Lab, Admin Block, 2nd floor',
        time: '9:30 AM-11:30 AM '
    },
    {
        event_id:142,
        name: 'Cognitare',
        description: 'Participants have to identify the given logo. Logos will be from computer science and engineering domain.(Team size will be two).',
        event_type: 'Logo Quiz',
        fee: 10,
        student1: 'Vishnupriya',
        s1_phone: 9495232973,
        student2: ' Cinana',
        s2_phone: 9496790041,
        staff: 'Ms.Jissin',
        prize: true,
        dept:'ads',
        reg: false,
        group: true,
        venue: 'Software Lab, Admin Block, 1st floor',
        time: '9:30 AM-10:30 AM '
    

    },
    {
        event_id:143,
        name: 'Mystic',
        description: 'Participants have to find solution for a given problem. Programming language will be either Python or C where they have to either turn off their screens or should be blindfolded before they type.(Team size will be two)',
        event_type: 'Blind Coding',
        fee: 'FREE',
        student1: 'Rose Benny',
        s1_phone: 9495089075,
        student2: 'Tibin',
        s2_phone: 9061201765,
        staff: 'Ms.Ancy K Sunny',
        prize: true,
        dept:'ads',
        reg: false,
        group: true,
        venue: 'Software Lab, Admin Block, 1st floor',
        time: '10:30 AM-12:00 PM '
    

    },
    {
        event_id:144,
        name: 'Fifa 23',
        description: 'Group wise event-FIFA.',
        event_type: 'Gaming',
        fee: 100,
        student1: 'Vaishak P ',
        s1_phone: 7994665201,
        student2: 'Snehal ',
        s2_phone: 9188899344,
        staff: 'Ms.Shumaisa M P ',
        prize: true,
        dept:'ads',
        reg: false,
        group: true,
        venue: 'Software Lab, Admin Block, 1st floor',
        time: '12:00 AM-2:00 PM'
    },
    {
        event_id: 13,
        name: 'Trinity Showdown',
        description: 'Battlegrounds Mobile India (BGMI): A TPP-FPP survival shooter game',
        event_type: 'Mobile Gamimg',
        fee: 200,
        student1: 'Dalven Jose',
        s1_phone: 6282306013,
        student2: 'Ms Amish Harinanadan',
        s2_phone: 9207882788,
        staff: 'Ms.Rahna C M',
        prize: true,
        dept: 'cse',
        reg: false,
        group: true,
        venue: 'S5 CSE B,Admin Block, 2nd floor',
        time: '10:00 AM - 3:00 PM'
    },
    {
        event_id:146,
        name: 'EDAC',
        description: 'Without using compiler',
        event_type: 'Error Detection',
        fee: 'FREE',
        student1: 'Jyothish ',
        s1_phone: 9207343728,
        student2: 'Akhil ',
        s2_phone: 8590266047,
        staff: 'Ms.Ancy K Sunny',
        prize: true,
        dept:'ads',
        reg: false,
        group: false,
        venue: 'Hardware Lab, Admin Block, 2nd floor',
        time: '1.30 PM-2.15 PM'
    },
    {
        event_id:147,
        name: 'Rigging',
        description: 'Participants have to assemble the PC within 20 minutes',
        event_type: 'PC Assembling',
        fee: 30,
        student1: 'Sankeerth ',
        s1_phone: 8075404811,
        student2: 'Nivida ',
        s2_phone: 9447001300,
        staff: 'Ms.Swathi Chandra',
        prize: true,
        dept:'ads',
        reg: false,
        group: true,
        venue: 'Software Lab, Admin Block, 1st floor',
        time: '2:00-3:00 PM'
    },
    {
        event_id:148,
        name: 'FutureForge',
        description: 'Questions will be display on projector screen',
        event_type: 'Output predictor ',
        fee: 'FREE',
        student1: 'Noyal',
        s1_phone: 7558037841,
        student2: 'Nandan',
        s2_phone: 9778147552,
        staff: 'Ms.Anit',
        prize: true,
        dept:'ads',
        reg: false,
        group: false,
        venue: 'Hardware Lab, Admin Block, 2nd floor',
        time: '2:15-3:00 PM'
    },
    {
        event_id:149,
        name: 'Spot Presentation',
        description: 'Topic will be display before 30 minutes of presentation',
        event_type: 'BlinkBrief',
        fee: 'FREE',
        student1: 'Shon ',
        s1_phone: 8943439495,
        student2: 'Akshay ',
        s2_phone: 8547925171,
        staff: 'Ms.Shumaisa M P',
        prize: true,
        dept:'ads',
        reg: false,
        group: false,
        venue: 'Hardware Lab, Admin Block, 2nd floor',
        time: '3:00-3:45 PM'
    },
    {
        event_id:150,
        name: 'QuickQuill',
        description: 'Online - (Ratatype)- Will provide online',
        event_type: 'Typing Speed',
        fee: 50,
        student1: ' Derlin',
        s1_phone: 7561874672,
        student2: 'Avani ',
        s2_phone: 8304972875,
        staff: 'Ms.Jijina MT',
        prize: true,
        dept:'ads',
        reg: false,
        group: false,
        venue: 'Software Lab, Admin Block, 1st floor',
        time: '3:00-3:30 PM'
    },
    {
        event_id:151,
        name: 'DeepSwap ',
        description: 'Online AI technique',
        event_type: 'DeepFake',
        fee: 30,
        student1: 'Mukalel Amal ',
        s1_phone: 6300217195,
        student2: 'Adith ',
        s2_phone: 9778100171,
        staff: 'Ms.Swathi Chandra ',
        prize: true,
        dept:'ads',
        reg: false,
        group: false,
        venue: 'Software Lab, Admin Block, 1st floor',
        time: '3:30-4:00 PM'
    },
   
];

export default AdsEventData;