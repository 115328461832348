const MechanicalEventData = [
    {
        event_id:81,
        name: 'Wanderlust',
        description: 'Workshop on offroad vehicles by Rainforest Driving Crew.',
        event_type: 'WORKSHOP',
        fee: '200',
        student1: 'Abdul Rasheed',
        s1_phone: 9562642492,
        student2: 'Bijay Babu',
        s2_phone: 7994197630,
        staff: 'Mr. Anil Johney',
        prize: true,
        dept:'me',
        reg: false,
        group: true,
        venue: 'CAD Lab ME Dept, 4th floor',
        time: '11.00 AM'
    },
    {
        event_id:82,
        name: 'Assembler',
        description: 'Re-assembling of dismantled parts in specified time.',
        event_type: 'ENGINE ASSEMBLY',
        fee: '100',
        student1: 'Akhil Rafeeque',
        s1_phone: 9048047642,
        student2: 'Yahya Mahmood',
        s2_phone: 9645773234,
        staff: 'Mr. Saju Augustine',
        prize: true,
        dept:'me',
        reg: false,
        group: false,
        venue: 'Production lab',
        time: '11:30 AM'

    },
    {
        event_id:83,
        name: 'Agni',
        description: 'A mock drill by fire force team.',
        event_type: 'FIRE FIGHTING DRILL',
        fee: 'FREE',
        student1: 'Tinu George',
        s1_phone: 9497299540,
        student2: 'Srikiran CM',
        s2_phone: 8137946578,
        staff: 'Mr. Shamin Muth KK',
        prize: false,
        dept:'me',
        reg: false,
        group: true,
        venue: 'College play ground',
        time: '02:00 PM'
    },

    {
        event_id:84,
        name: 'Et al.',
        description: 'Technical paper presentation.',
        event_type: 'TECHNICAL PAPER',
        fee: '150',
        student1: 'Safwan',
        s1_phone: 9633501951,
        student2: 'Anumol',
        s2_phone: 9778057049,
        staff: 'Dr. Sridharan',
        prize: true,
        dept:'me',
        reg: false,
        group: false,
        venue: 'S7 ME, Ground Floor',
        time: '01:00 PM'
    },
    
    {
        event_id:85,
        name: 'Click',
        description: 'Photography competition.',
        event_type: 'TECHNICAL PHOTOGRAPHY',
        fee: 'FREE',
        student1: 'Sayanth',
        s1_phone: 9188164485,
        student2: 'Hrithik',
        s2_phone: 6235315977,
        staff: 'Mr. Vyshakh M',
        prize: true,
        dept:'me',
        reg: false,
        group: true,
        venue: 'Parking lot,ME,Ground floor',
        time: '12:00 PM'
    },

    {
        event_id:86,
        name: 'Vayu',
        description: 'Workshop on design and handon applications of drones.',
        event_type: 'WORKSHOP',
        fee: '150',
        student1: 'Vyshnav R',
        s1_phone: 8590990061,
        student2: 'Vaishanav PV',
        s2_phone: 9747760247,
        staff: 'Mr. Ryne PM,',
        prize: true,
        dept:'me',
        reg: false,
        group: true,
        venue: 'S7 ME-B,Ground floor',
        time: '9:00 AM'
    },


    {
        event_id:87,
        name: 'Carto',
        description: 'Efficycle bike ride try out.',
        event_type: 'Efficycle bike ride and demonstration.',
        fee: 'FREE',
        student1: 'Avinash',
        s1_phone: 7511114010,
        student2: 'Sayooj',
        s2_phone: 6235068016,
        staff: 'Mr. Shamin Muth KK,',
        prize: false,
        dept:'me',
        reg: false,
        group: true,
        venue: 'Basketball court.',
        time: '01:00 PM'
    },

    {
        event_id:88,
        name: 'Lift',
        description: 'DRONE SHOW by VJEC students.',
        event_type: 'DRONE SHOW',
        fee: 'FREE',
        student1: 'Vishnu Vijayan',
        s1_phone: 7034361784,
        student2: 'Jithin Das',
        s2_phone: 9747038139,
        staff: 'Mr. Jayachandran',
        prize: false,
        dept:'me',
        reg: false,
        group: true,
        venue: 'S5 ME-A,Ground floor.',
        time: '10:00 AM'
    },
    {
        event_id:89,
        name: 'Quizophile',
        description: 'Quiz based on automobile.',
        event_type: 'AUTO QUIZ',
        fee: '100',
        student1: 'Anusree P Nair ',
        s1_phone: 6238052538,
        student2: 'Alen Jose',
        s2_phone: 9400194570,
        staff: 'Mr. Robin CR',
        prize: true,
        dept:'me',
        reg: false,
        group: false,
        venue: 'College play ground.',
        time: '10:00 AM'
    },
   

    {
        event_id:90,
        name: 'JARVIS',
        description: 'Finding the correct name of given tool.',
        event_type: 'TOOL FINDER',
        fee: '100',
        student1: 'Vipin IV',
        s1_phone: 9037534682,
        student2: 'Nazil Arsh',
        s2_phone: 9778188077,
        staff: ' Mr. Aji Augustine',
        prize: true,
        dept:'me',
        reg: false,
        group: false,
        venue: 'College auditorium.',
        time: '01:45 PM'
    },
    {
        event_id:91,
        name: 'AK47',
        description: 'Ammunition Display by Kerala Armed Police Battalion.',
        event_type: 'Ammunition Display',
        fee: 'FREE',
        student1: 'Adithya Madhu',
        s1_phone: 7560870781,
        student2: 'Nirmal Thomas',
        s2_phone: 7558992887,
        staff: 'Mr. Appu Kurian',
        prize: false,
        dept:'me',
        reg: false,
        group: true,
        venue: 'S3 ME,1st Floor.',
        time: '11:00 AM'
    },

    {
        event_id:92,
        name: 'Dhoom',
        description: 'Driving GO- CART on the given track.',
        event_type: 'GO-CART RIDING',
        fee: 'FREE',
        student1: 'Govind ',
        s1_phone: 9656431626,
        student2: 'Sharon Scaria ',
        s2_phone: 8075486498,
        staff: ' Mr. Midhun Mukundan',
        prize: false,
        dept:'me',
        reg: false,
        group: true,
        venue: 'BME Workshop, Near canteen.',
        time: '11:30 AM'
    },

    {
        event_id:93,
        name: 'Adrenaline',
        description: 'SAE VEHICLE DISPLAY (ATV, F3,GO-CART, ELECTRIC CAR).',
        event_type: 'CAR DISPLAY',
        fee: 'FREE',
        student1: 'Adarsh Benny ',
        s1_phone: 8590095061,
        student2: 'Rinto KA' ,
        s2_phone: 9207249356,
        staff: 'Mr. Shamin Muth KK',
        prize: false,
        dept:'me',
        reg: false,
        group: true,
        venue: 'S5 ME, Ground floor.',
        time: '11:30 AM'
    },

    {
        event_id:94,
        name: 'Innovating',
        description: 'Display of student Btech projects and models of VJEC students.',
        event_type: 'S7 Projects and technical exibit stalls',
        fee: 'FREE',
        student1: 'Sawad ',
        s1_phone: 7293014636,
        student2: 'Aswin' ,
        s2_phone: 7907005906,
        staff: 'Mr. Ramprasidh',
        prize: false,
        dept:'me',
        reg: false,
        group: false,
        venue: 'S7 ME B, Ground floor.',
        time: '10:00 AM'
    },

    {
        event_id:95,
        name: 'Alpha',
        description: 'Restoration of Model Robots.',
        event_type: 'RESTORATION OF MODEL ROBOTS',
        fee: 'FREE',
        student1: 'Abhinand ',
        s1_phone: 7012196354,
        student2: 'Mirwaiz' ,
        s2_phone: 7025093887,
        staff: 'Mr. Robin CR',
        prize: false,
        dept:'me',
        reg: false,
        group: true,
        venue: 'S5 ME,Ground Floor',
        time: '1:30 PM'
    },

]

export default MechanicalEventData;