const EeeEventData = [
    {
        event_id: 203,
        name: 'Techno Fusion',
        description: 'Competition of circuit based projects ',
        event_type: 'Technical Competition',
        fee: 'FREE',
        student1: 'Abhishek V K',
        s1_phone: 9207546269,
        student2: '',
        s2_phone: 0,
        staff: 'Tutors',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Machines Lab,Admin Block,1st Floor',
        time: '10:00 AM-3:00 PM'
    },
    {
        event_id: 42,
        name: 'TechQuest',
        description: 'Quiz competition',
        event_type: 'Technical Competition',
        fee: 'FREE',
        student1: ' Anjel Sibi',
        s1_phone: 9847918264,
        student2: '',
        s2_phone: 0,
        staff: ' Ms. Tintu George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'MCQ',
        time: '11:00 AM-11:15 AM'
    },
    {
        event_id: 43,
        name: 'TechTalk Showdown',
        description: ' Presentation of Technical Topics',
        event_type: 'Technical Competition',
        fee: 'FREE',
        student1: 'Smera Sanil',
        s1_phone: 9037385919,
        student2: '',
        s2_phone: 0,
        staff: ' Mr. Prabin James',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Google Meet',
        time: '10:00 AM-11:00 AM'
    },
    {
        event_id: 44,
        name: 'Speakup',
        description: 'Debate on technical topics ',
        event_type: 'Technical Competition',
        fee: 'FREE',
        student1: 'NR Karthik',
        s1_phone: 8129050990,
        student2: '',
        s2_phone: 0,
        staff: 'Ms. Tintu George ',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'S7 EEE,Admin Block,1st Floor',
        time: '10:00 AM-12:00 PM'
    },
    {
        event_id: 45,
        name: ' Brainup ',
        description: 'Event based on ideas',
        event_type: 'Technical Competition',
        fee: 'FREE',
        student1: 'Elizabeth',
        s1_phone: 7560859522,
        student2: '',
        s2_phone: 0,
        staff: 'Ms. Tintu George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Google Meet',
        time: '11:00 AM-12:00 PM'
    },
    {
        event_id: 46,
        name: 'Innopitch',
        description: 'Propose a new project topic',
        event_type: 'Technical Competition',
        fee: 'FREE',
        student1: 'Vivek P V',
        s1_phone: 8547489304,
        student2: '',
        s2_phone: 0,
        staff: 'Mr.Rijoy G N',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Google Meet',
        time: '12:00 PM-1:00 PM'
    },
    {
        event_id: 47,
        name: ' Circuit sprint',
        description: 'Analysing circuits , detection and correction of errors',
        event_type: 'Technical Competition',
        fee: 'FREE',
        student1: 'Souraj',
        s1_phone: 8547487101,
        student2: '',
        s2_phone: 0,
        staff: 'Mr. Rijoy G N',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Basic Electrical Workshop Lab',
        time: '10:30 AM-11:00 AM'
    },
    {
        event_id: 48,
        name: 'El tornado',
        description: 'Solving electrical wiring',
        event_type: 'Technical Competition',
        fee: '',
        student1: 'Karthik C',
        s1_phone: 8590910734,
        student2: '',
        s2_phone: 0,
        staff: 'Mr. Rijoy G N',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Basic Electrical Workshop Lab',
        time: '10:30 AM-11:00 AM'
    },
    {
        event_id: 49,
        name: 'Dr. Driving',
        description: 'Competition to showcase the driving skills',
        event_type: 'Game Competition',
        fee: 20,
        student1: 'Sarang',
        s1_phone: 9400478028,
        student2: '',
        s2_phone: 0,
        staff: 'Mr. Rijoy G N',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Measurement lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 50,
        name: 'Autocraft',
        description: 'Assembling Of Robo Car',
        event_type: 'Game Competition',
        fee: 20,
        student1: 'Ashwanth K M',
        s1_phone: 9495595747,
        student2: '',
        s2_phone: 0,
        staff: 'Mr. Rijoy G N',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'S7 EEE',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 51,
        name: 'Tech kick',
        description: 'Robots compete against each other in a soccer',
        event_type: 'Game Competition',
        fee: 20,
        student1: 'Partheev Krishnan',
        s1_phone: 6282658677,
        student2: '',
        s2_phone: 0,
        staff: 'Mr. Rijoy G N',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Measurement lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 52,
        name: 'Feel the wet',
        description: '2 robots, a land bot and a water bot',
        event_type: 'Game Competition',
        fee: 20,
        student1: 'Arjun V',
        s1_phone: 8590984501,
        student2: '',
        s2_phone: 0,
        staff: 'Mr. Rijoy G N',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Gandhi Pond',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 53,
        name: 'Speed Up',
        description: 'Robot car racing competition',
        event_type: 'Game Competition',
        fee: 20,
        student1: 'Amegh',
        s1_phone: 9207646087,
        student2: '',
        s2_phone: 0,
        staff: 'Mr. Rijoy G N',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Measurement lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 54,
        name: 'High pleasure',
        description: 'To create a water rocket',
        event_type: 'Game Competition',
        fee: 20,
        student1: 'Abijith Rajeevan',
        s1_phone: 8921327153,
        student2: '',
        s2_phone: 0,
        staff: 'Mr. Rijoy G N',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'College ground',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 55,
        name: 'Bottle Flip',
        description: 'Fliping of The bottle',
        event_type: 'Game',
        fee: 10,
        student1: 'Avantika T',
        s1_phone: 7306079858,
        student2: '',
        s2_phone: '',
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Measurement lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 56,
        name: 'Fly High ',
        description: 'papper plane flying',
        event_type: 'Game',
        fee: 10,
        student1: 'Aibel Jaison',
        s1_phone: 8157804661,
        student2: '',
        s2_phone: 0,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: true,
        venue: 'Measurement lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 57,
        name: 'plinko',
        description: 'directing the ball to the basket',
        event_type: 'Game',
        fee: 10,
        student1: 'Shivang Suresh',
        s1_phone: 8547120207,
        student2: '',
        s2_phone: 0,
        staff: 'Ms. Teena George',
        prize: true,
        dept: '',
        reg: false,
        group: false,
        venue: 'Ground Side',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 58,
        name: 'Dart Throw',
        description: 'Aiming the needle at the center point',
        event_type: 'Game',
        fee: 10,
        student1: 'Ashuthosh',
        s1_phone: 9745215521,
        student2: '',
        s2_phone: 0,
        staff: 'Ms. Teena George',
        prize: true,
        dept: '',
        reg: false,
        group: false,
        venue: 'Gallery',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 59,
        name: 'Ring Toss',
        description: 'Throwing circular ring at a stick',
        event_type: 'Game',
        fee: 10,
        student1: 'Theertha',
        s1_phone: 7558817709,
        student2: '',
        s2_phone: 0,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Measurement lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 60,
        name: 'penalty shootout',
        description: 'hit the ball in to the post ',
        event_type: 'Game',
        fee: 20,
        student1: 'Abhijith',
        s1_phone: 8921327153,
        student2: '',
        s2_phone: 0,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Ground Side',
        time: '9:00 AM-12:00 PM',
        spot: true,
    },
    {
        event_id: 204,
        name: 'Stump Throw',
        description: 'Hit the stump with the ball ',
        event_type: 'Game',
        fee: 20,
        student1: ' Amegh ',
        s1_phone: 9207646087,
        student2: '',
        s2_phone: 0,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Ground Side',
        time: '9:00 AM-12:00 PM',
        spot: true,
    },
    {
        event_id: 205,
        name: 'Balloon Shooting',
        description: 'Hit the balloon with an arrow or gun',
        event_type: 'Game',
        fee: 10,
        student1: 'Abhishek',
        s1_phone: 9207546269,
        student2: '',
        s2_phone: 0,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Ground Side',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 206,
        name: 'Pyramid Construction',
        description: 'construct a pyramid within the given time',
        event_type: 'Game',
        fee: 10,
        student1: 'Amaljith',
        s1_phone: 8590240971,
        student2: '',
        s2_phone: 0,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Measure Lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 207,
        name: 'Buzz Wire',
        description: 'Pass through the wire without getting buzzed.',
        event_type: 'Game',
        fee: 10,
        student1: 'Shanat',
        s1_phone: 9562431018,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Measurement lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },

    {
        event_id: 208,
        name: 'Rubiks Cube Solving',
        description: 'solve the given rubiks cube.',
        event_type: 'Game',
        fee: 20,
        student1: 'Elizabeth T Mani',
        s1_phone: 7560859552,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Measurement lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },

    {
        event_id: 209,
        name: 'FLIP TAC TOE',
        description: 'Flip the cup 3 in-a-row.',
        event_type: 'Game',
        fee: 10,
        student1: 'Asheen Prakashan',
        s1_phone: 8848539535,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Measurement lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },

    {
        event_id: 210,
        name: 'WALKER BOTTLE',
        description: 'Move the cup by fliping the bottle.',
        event_type: 'Game',
        fee: 10,
        student1: 'Lisa Mary Mathew',
        s1_phone: 9446062140,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Measurement lab',
        time: '9:00 AM-3:00 PM',
        spot: true,

    },

    {
        event_id: 211,
        name: 'CORN HOLE',
        description: 'Direct the mini pillow into hole.',
        event_type: 'Game',
        fee: 10,
        student1: 'Vaishnavi E',
        s1_phone: 9207253473,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Ground side',
        time: '9:00 AM-3.00 PM',
        spot: true,
    },

    {
        event_id: 212,
        name: 'CARROM BOARD',
        description: 'Strike the red coin to the pocket.',
        event_type: 'Game',
        fee: 50,
        student1: 'Shivang Suresh',
        s1_phone: 8547120207,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'S3 EEE',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },

    {
        event_id: 213,
        name: 'SPEED ERASER',
        description: 'luck to get the number where you put the coin while rolling the dies.',
        event_type: 'Game',
        fee: 10,
        student1: 'Shivang Suresh',
        s1_phone: 8547120207,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'S3 EEE',
        time: '9.00 AM-3.00 PM',
        spot: true,
    },
    {
        event_id: 214,
        name: 'Lock And Key',
        description: 'Finding The key for the lock given',
        event_type: 'Game',
        fee: 10,
        student1: 'Stelbin Joy',
        s1_phone: 8590749126,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Measurement Lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 215,
        name: 'Periods Cramps',
        description: 'Experiencing the pain during the periods using the period cramps equipment',
        event_type: 'Game',
        fee: 30,
        student1: 'Nandhana',
        s1_phone: 9605146713,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Measurement Lab',
        time: '9:00 AM-3:00 PM',
        spot: true,
    },
    {
        event_id: 216,
        name: 'Battle ground India',
        description: 'Online game',
        event_type: 'Game',
        fee: 100,
        student1: 'Amalraj K',
        s1_phone: 9400758012,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Online',
        time: '20:00 PM',
        spot: true,
    },
    {
        event_id: 217,
        name: 'Free Fire',
        description: 'Online game',
        event_type: 'Game',
        fee: 100,
        student1: 'Shivang Suresh',
        s1_phone: 8547120207,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Online',
        time: '20:00 PM',
        spot: true,
    },
    {
        event_id: 217,
        name: 'Treasure Hunt',
        description: 'Finding the treasure',
        event_type: 'Game',
        fee: 50,
        student1: 'Abhinav',
        s1_phone: 85474388625,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: false,
        group: false,
        venue: 'Measurement Lab',
        time: '20:00 PM',
    },
    {
        event_id: 218,
        name: 'PESS',
        description: 'Pess mobile gaming',
        event_type: 'Game',
        fee: 50,
        student1: 'Vyshanav M K',
        s1_phone: 8157925467,
        staff: 'Ms. Teena George',
        prize: true,
        dept: 'eee',
        reg: true,
        group: false,
        venue: 'online',
        time: '',
    },

];

export default EeeEventData;