const CscyEventData = [
    {
        event_id: 161,
        name: 'Cyber Hunt',
        description: 'QR hunt, solve puzzles, form username, use numbers, claim victory.',
        event_type: 'Competition',
        fee: 30,
        student1: 'Anirudh A',
        s1_phone: 8606594032,
        student2: 'Deepu C P ',
        s2_phone: 6238582794,
        staff: 'Ms.Amrutha P M',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: false,
        venue: 'T20A, Admin Block, 3rd floor',
        time: '09.30 AM'
    },
    {
        event_id: 162,
        name: 'Golazo',
        description: 'competition of Efootball game ',
        event_type: 'Competiton',
        fee: 30,
        student1: 'Aksh Jolly ',
        s1_phone: 8113844603,
        student2: 'ABHIRAM P V',
        s2_phone: 6238008398,
        staff: 'Ms.Amrutha P M',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: true,
        venue: 'Drawing Hall, Admin Block, 3rd floor',
        time: '10:00 AM'

    },
    {
        event_id: 163,
        name: 'Code correction',
        description: 'Find the error in the code without using software',
        event_type: 'Competition',
        fee: 30,
        student1: 'Akhina PV',
        s1_phone: 8111943540,
        student2: 'Saniya Sudhan ',
        s2_phone: 6238377855,
        staff: 'Ms.Amrutha P M',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: false,
        venue: 'ACRC LAB, Admin Block, 2nd floor',
        time: '14:00'
    },
    {
        event_id: 164,
        name: 'Mission Impossible',
        description: 'Navigate laser maze, first to cross wins the unbeatable challenge',
        event_type: 'Laser maze and games ',
        fee: 40,
        student1: 'Swathi',
        s1_phone: 9400625323,
        student2: 'Lajin',
        s2_phone: 6238766241,
        staff: 'Ms.Anu Treesa George',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: true,
        venue: 'T20B,admin block',
        time: '10:00 AM'
    },
    {
        event_id: 165,
        name: 'Vision AI',
        description: 'Contest: Replicate given image using AI image generator. Learn techniques.',
        event_type: 'CAI Image Generation - Generative Vision Quest ',
        fee: 25,
        student1: 'Melgibson Shiju',
        s1_phone: 9061354921,
        student2: 'Navaneeth a s ',
        s2_phone: 6282281637,
        staff: 'Ms.Anu Treesa George',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: false,
        venue: 'ACRC lab,admin block,2nd floor',
        time: '10:00 AM'
    },
    {
        event_id: 166,
        name: 'Chakravyuham',
        description: 'Competition of breaking a web application ',
        event_type: 'Web Security',
        fee: 30,
        student1: 'Adithya Gopalakrishnan',
        s1_phone: 7012958504,
        student2: 'swathi',
        s2_phone: 9400625323,
        staff: 'Ms.Amrutha P M',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: false,
        venue: 'T20A,admin block,3rd floor',
        time: '12:45 PM'
    },
    {
        event_id: 167,
        name: 'Erre',
        description: 'Shooting game: four points, no jumps, perfect shots win prizes.',
        event_type: 'Throw',
        fee: 30,
        student1: 'Muhamed Musthafa',
        s1_phone: 8921335280,
        student2: 'Sarang C',
        s2_phone: 7909253003,
        staff: 'Ms.Anu Treesa George',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: false,
        venue: 'BASKETBALL COURT ',
        time: '13:30 PM'
    },
    {
        event_id: 168,
        name: 'Relay Coding',
        description: 'Team of three, silent coding relay. First starts, others continue.Group(3 members)',
        event_type: 'Relay Coding',
        fee: 30,
        student1: 'Faheema',
        s1_phone: 9207690581,
        student2: 'Lajin ',
        s2_phone: 6238766241,
        staff: 'Ms.Amrutha P M ',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: false,
        venue: 'T20B',
        time: '12:45 PM'
    },

    {
        event_id: 169,
        name: 'Binary Bingo ',
        description: 'Binary bingo: convert decimals, mark cards, and boost binary skills.',
        event_type: 'Bit Buster Bingo ',
        fee: 10,
        student1: 'Varna O V ',
        s1_phone: 6238634461,
        student2: 'Shreya Sreekumar',
        s2_phone: 9037064843,
        staff: 'Ms.Anu Treesa George',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: true,
        venue: 'Drawing Hall,Admin block,3rd floor',
        time: '10:00 AM'
    },
    {
        event_id: 170,
        name: '2048',
        description: 'Combine tiles strategically, reach 2048. Challenge: plan and match skill.',
        event_type: 'Game ',
        fee: 10,
        student1: 'Ashwin Sushil',
        s1_phone: 8075972709,
        student2: 'Alka Sajeevan',
        s2_phone: '',
        staff: 'Sr.Reema Jose ',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: false,
        venue: 'Drawing Hall,Admin block,3rd floor',
        time: '10:00 AM'
    },
    {
        event_id: 171,
        name: 'DecoDynamo',
        description: 'Decrypt challenge: crack the code, find the word, beat the clock.',
        event_type: 'Code Breaker Challenge ',
        fee: 10,
        student1: 'Alka Sajeevan',
        s1_phone: 9074281356,
        student2: 'Mathew George',
        s2_phone: '',
        staff: 'Sr.Reema Jose ',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: false,
        venue: 'Drawing Hall,Admin block,3rd floor',
        time: '12:45 PM'
    },
    {
        event_id: 172,
        name: 'Cyber buzz',
        description: 'With a team of two,Crack the cyber quiz and win the prize',
        event_type: 'Cyber buzz',
        fee: 20,
        student1: 'Sreelakshmi Suresh',
        s1_phone: 9778114578,
        student2: 'Mathew George',
        s2_phone: '',
        staff: 'Sr.Reema Jose ',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: false,
        venue: 'ACRC Lab',
        time: '15:00 PM'
    },
    {
        event_id: 173,
        name: 'Ring Toss',
        description: 'Ring toss is a game where rings are aimed at a gift you need from behind the line.',
        event_type: 'Ring Toss ',
        fee: 10,
        student1: 'Mathew George  ',
        s1_phone: 7510795138,
        student2: 'Edwin ',
        s2_phone: 7912535604,
        staff: 'Sr.Reema Jose ',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: true,
        venue: 'Drawing Hall,Admin block,3rd floor',
        time: '10:00 AM',
        spot: true,
    },
    {
        event_id: 174,
        name: 'PictoCipherWhiz',
        description: 'Tech-themed Pictionary with QR codes. Guess words as time ticks.Group(3 members)',
        event_type: 'QR Code Pictionary',
        fee: 10,
        student1: 'Shreya Sreekumar',
        s1_phone: 9037064843,
        student2: 'Varna O V ',
        s2_phone: 7912535604,
        staff: 'Sr.Reema Jose ',
        prize: true,
        dept: 'cscy',
        reg: false,
        group: true,
        venue: 'Drawing Hall,Admin block,3rd floor',
        time: '12:45 PM'
    },
];


export default CscyEventData;