const AeiEventData = [
    {
        event_id:181,
        name: 'Project Expo',
        description: 'Project Expo is a technical event in which the participants should submit their project ideas.',
        event_type: 'Competition',
        fee: 'FREE',
        student1: 'Alen Joe Prince',
        s1_phone: 8113864115,
        student2: 'Rony Siby',
        s2_phone: 9447688158,
        staff: 'Mr. Thomas M S',
        prize: true,
        dept:'aei',
        reg: false,
        group: true,
        venue: 'Gandhi statue premises',
        time: '9:30 am-4:10 pm'
    },
    {
        event_id:182,
        name: 'Technical Exhibition',
        description: 'A platform for the innovative technology in automation/instrumentation field.',
        event_type: 'Exhibition',
        fee: 'FREE',
        student1: 'Aswin T S',
        s1_phone: 6235861528,
        student2: 'Aida Thomas',
        s2_phone: 9446747703,
        staff: 'Mr. Jollykutty Sebastian',
        prize: false,
        dept:'aei',
        reg: false,
        group: true,
        venue: 'Gandhi statue premises',
        time: '9:30 am-4:10 pm'

    },
    {
        event_id:183,
        name: 'Robo Soccer',
        description: 'A manual robot which can compete on an arena specially designed for robotic soccer match.',
        event_type: 'Competition',
        fee: 50,
        student1: 'Tom Jessan',
        s1_phone: 7025918930,
        student2: 'Alen Johns',
        s2_phone: 6282302366,
        staff: 'Mr. Shinu M M',
        prize: true,
        dept:'aei',
        reg: false,
        group: true,
        venue: 'NearGandhi statue',
        time: '10:30 am-12:30 pm'
    },
    {
        event_id:184,
        name: 'Robo Race',
        description: 'A robot either wired or wireless within the specified area that can be operated manually and can travel through all turns of the track.',
        event_type: 'Competition',
        fee: 50,
        student1: 'Jude Jomon',
        s1_phone: 7025668176,
        student2: 'Noel Biju',
        s2_phone: 9207167166,
        staff: 'Mr. Shinu M M',
        prize: true,
        dept:'aei',
        reg: false,
        group: false,
        venue: 'Near Gandhi statue ',
        time: '10:30 am-12:30 pm'
    },
    {
        event_id:185,
        name: 'Treasure hunt',
        description: 'To boost both your logic and technique, the idea of hunting for hidden treasures with different level technically.',
        event_type: 'Game',
        fee: 50,
        student1: 'Akshar Mohan',
        s1_phone: 9495205950,
        student2: 'Mohammed Amal',
        s2_phone: 8138008550,
        staff: 'Mrs. Jinsa Mathew',
        prize: true,
        dept:'aei',
        reg: false,
        group: true,
        venue: 'Near Gandhi statue',
        time: '11:00 am - 1:00 pm'
    },
    {
        event_id:186,
        name: 'Paper Presentation',
        description: 'An individual competition where each participant is required to make a paper and then present it in front of the juries.',
        event_type: 'Competition',
        fee: 'FREE',
        student1: 'Akshay M',
        s1_phone: 8138980724,
        student2: 'Muhammed Razeen',
        s2_phone: 8086640466,
        staff: 'Mrs. Reshma K V',
        prize: true,
        dept:'aei',
        reg: false,
        group: false,
        venue: 'S7 AEI classroom',
        time: '12.00 pm-1:00 pm'
    },
    {
        event_id:187,
        name: 'Technical Quiz',
        description: 'A contest in which participants test what they know technically by answering questions.',
        event_type: 'Competition',
        fee: 'FREE',
        student1: 'Vyshnav K',
        s1_phone: 9778028179,
        student2: 'Anand Sajeevan',
        s2_phone: 9995734587,
        staff: 'Mrs. Reshma K V',
        prize: true,
        dept:'aei',
        reg: false,
        group: false,
        venue: 'S3 AEI classroom',
        time: '1:00 pm-3:00 pm'
    },
    {
        event_id:188,
        name: 'Idea Competition',
        description: 'Share ideas for innovation. And present in front of juries .',
        event_type: 'Competition',
        fee: 'FREE',
        student1: 'Hirandeep T',
        s1_phone: 9633925954,
        student2: 'Jasmine P T K',
        s2_phone: 9497619921,
        staff: 'Mrs. Jinsa Mathew',
        prize: true,
        dept:'aei',
        reg: false,
        group: true,
        venue: 'S5 AEI classroom',
        time: '1:00 pm -3:00 pm'
    },
    {
        event_id:189,
        name: 'Signal Generation',
        description: 'To Generate the given waveform by using stardad signal with combination of signal processing circuit/ software.',
        event_type: 'Competition',
        fee: 'FREE',
        student1: 'Alan Sunny',
        s1_phone: 9946174595,
        student2: 'Sreenav V',
        s2_phone: 7560953869,
        staff: 'Mrs. Shamya M',
        prize: true,
        dept:'aei',
        reg: false,
        group: false,
        venue: 'Electronics lab',
        time: '12:00 pm -1:00 pm'
    },
    {
        event_id:190,
        name: 'Circuit Debugging',
        description: 'Analyzing a circuit and detecting and correcting if any errors.',
        event_type: 'Competition',
        fee: 'FREE',
        student1: 'Dipuraj',
        s1_phone: 9496334384,
        student2: 'Anurag E V',
        s2_phone: 8606880170,
        staff: 'Mrs. Shamya A',
        prize: true,
        dept:'aei',
        reg: false,
        group: false,
        venue: 'Electronics lab',
        time: '11:00 am - 12:00 pm'
    },
    {
        event_id:191,
        name: 'Integration Bee',
        description: 'Different forms of integration questions will be given, candidates have to solve it.',
        event_type: 'Competition',
        fee: 'FREE',
        student1: 'Aswin Vinod C',
        s1_phone: 6282312363,
        student2: 'Merin Saji',
        s2_phone: 9605766939,
        staff: 'Mr. Dhanoj M',
        prize: true,
        dept:'aei',
        reg: false,
        group: false,
        venue: 'Research Lab',
        time: '2:00 pm-3:00 pm'
    },
];

export default AeiEventData;