const CseEventData = [
  {
    event_id: 10,
    name: "CyberRelay",
    description: "A workshop on Cyber Security and Ethical Hacking. Early Bird:49 Regular Fee: 99 . Speaker Mr. Farhan Bin Fazil",
    event_type: "Workshop",
    fee: 99,
    student1: "Sreeram Pavithran",
    s1_phone: 7736024269,
    student2: "Vaishnav Krishna",
    s2_phone: 7736966011,
    staff: "Ms. Ashila K A",
    prize: false,
    dept: "cse",
    reg: false,
    group: false,
    venue: "Varikkattu Hall",
    time: "10:00 AM - 11:30 AM",
  },
  {
    event_id: 1,
    name: "TechSphere",
    description:
      "An opportunity to present your tech projects competitively and demonstrate your innovative prowess among peers in the field.",
    event_type: "Project Expo",
    fee: "FREE",
    student1: "Neha Premarajan",
    s1_phone: 9916801845,
    student2: "Treesa",
    s2_phone: 9778338230,
    staff: "Ms. Manju M",
    prize: true,
    dept: "cse",
    reg: false,
    group: true,
    venue: "S5 CSE A,Admin BLOCK,2nd Floor",
    time: "9:00 AM-4:00 PM",
  },
  {
    event_id: 2,
    name: "Terminal Treasures",
    description:
      "Exploring Linux systems through terminal and capture flag clues that lead to next stops in the speed-run to final destination. Group(2 members)",
    event_type: "Capture The Flag",
    fee: "FREE",
    student1: "Anson Leon Sebastian",
    s1_phone: 6282395097,
    student2: "Abhay",
    s2_phone: 9995008670,
    staff: "Ms. Divya K",
    prize: true,
    dept: "cse",
    reg: false,
    group: true,
    venue: "S3 CSE A, Admin BLOCK,2nd Floor",
    time: "10:30 AM",
  },
  {
    event_id: 3,
    name: "TechWiz",
    description:
      "Tech Quiz to test participants knowledge of science, technology and engineering. Include rounds like trivia, general knowledge, tech terms, puzzles and coding. Early bird: 50 registrations free. Regular fee: 30",
    event_type: "Quiz Competition",
    fee: 30,
    student1: "Sona Saji",
    s1_phone: 9947102198,
    student2: "Sreya",
    s2_phone: 7994141321,
    staff: "Ms. Sreeraji",
    prize: true,
    dept: "cse",
    reg: false,
    group: false,
    venue: "Computer Centre,Admin BLOCK,2nd Floor",
    time: "12:00 PM",
  },
  {
    event_id: 4,
    name: "Enigma",
    description:
      "Participants have to find solution for a given problem using any programming language (available from HackerRank coding Environment). Particpants must have a hackerrank account",
    event_type: "Hackerrank",
    fee: "FREE",
    student1: "Ashik Jhonson",
    s1_phone: 9995460280,
    student2: "Ananya",
    s2_phone: 9400624824,
    staff: "Dinsha P K",
    prize: true,
    dept: "cse",
    reg: false,
    group: false,
    venue: "Computer Centre,Admin BLOCK,2nd Floor",
    time: "11:00 AM",
  },
  {
    event_id: 5,
    name: "WebTag",
    description:
      "Welcome to WebTag Challenge 2023, where coding meets creativity! Test your HTML and CSS skills in a hands-on environment. Replicate a pre-designed web page, focusing on details like layout and styling.",
    event_type: "Web Designing",
    fee: "FREE",
    student1: "K V Henath Raj",
    s1_phone: 8590339168,
    student2: "P J Joseph",
    s2_phone: 9495107111,
    staff: "Ms. Rajitha K V",
    prize: true,
    dept: "cse",
    reg: false,
    group: false,
    venue: "Computer Centre,Admin BLOCK,2nd Floor",
    time: "2:00 PM",
  },
  {
    event_id: 6,
    name: "Ctrl+Create",
    description:
      "KeyCraft Challenge: Unleash your skills! Assemble cutting-edge keyboards against time to become the Ultimate KeyMaster in a thrilling event where every keystroke counts!",
    event_type: "Keyboard Assembling",
    fee: 20,
    student1: "Riya George",
    s1_phone: 6238202526,
    student2: "Justine",
    s2_phone: 7907338036,
    staff: "Mr. Abdul Latheef",
    prize: true,
    dept: "cse",
    reg: false,
    group: false,
    venue: "S3 CSE A,Admin BLOCK,2nd Floor",
    time: "11:00 AM",
  },
  {
    event_id: 7,
    name: "Pixel Perfect",
    description:
      "Pixel Perfect Snapshot: A tech fest focused on prompt engineering. Participants create themed images using prompts, with a judging panel selecting the best image based on the given scenario.",
    event_type: "Prompt Engineering",
    fee: 20,
    student1: "Ann Riya Siby",
    s1_phone: 9495987884,
    student2: "Hrithika",
    s2_phone: 8301859280,
    staff: "Mr. Rijin I K",
    prize: true,
    dept: "cse",
    reg: false,
    group: false,
    venue: "Computer Centre,Admin BLOCK,2nd Floor",
    time: "3:00 PM",
  },
  {
    event_id: 8,
    name: "TECHTOPIA",
    description:
      "We will be showcasing a wide variety of products, including desktop , gaming PCs, workstations, servers, storage devices, graphics cards, processors, motherboards, memory,software. ",
    event_type: "PC Showcase",
    fee: "FREE",
    student1: "Nachiketas sushil ",
    s1_phone: 9188181375,
    student2: "Sharon Dewson",
    s2_phone: 9074475521,
    staff: "Ms. Aswathi Radhakrishnan",
    prize: false,
    dept: "cse",
    reg: false,
    group: false,
    venue: "S5 CSE-A class,Admin BLOCK,2nd Floor",
    time: "9:00 AM-4:00 PM",
  },
  {
    event_id: 9,
    name: "PlayForge",
    description:
      "2D game creation in 'PlayForge: Unity Unveiled.' Join a brief, code-free session to explore Unity's power and get inspired!",
    event_type: "Workshop",
    fee: "FREE",
    student1: "Jewel John",
    s1_phone: 9947807480,
    student2: "Aswin K",
    s2_phone: 9544815670,
    staff: "Ms. Ramitha",
    prize: false,
    dept: "cse",
    reg: false,
    group: false,
    venue: "Computer Centre,Admin BLOCK,2nd Floor",
    time: "9:00 AM-10:30 AM",
  },
  {
    event_id: 11,
    name: "GAME-DOME",
    description:
      "Where PlayStation comes alive! Experience the latest titles in a cutting-edge gaming environment",
    event_type: "Playstation gaming",
    fee: 70,
    student1: "Ameya P V",
    s1_phone: 9778135546,
    student2: "Anugraha V V",
    s2_phone: 8891332151,
    staff: "Ms. Tintu Devasia",
    prize: false,
    dept: "cse",
    reg: false,
    group: false,
    venue: "S5 CSE C,Admin BLOCK,2nd Floor",
    time: "9:00 AM - 4:00 PM",
    spot: true,
  },
  {
    event_id: 12,
    name: "Magnum",
    description:
      "Valorant tournament (Tournament Mode – unrated),pc game event.",
    event_type: "PC Gaming",
    fee: 300,
    student1: "Amal Binoy",
    s1_phone: 9744970409,
    student2: "Tanvi Prashanth",
    s2_phone: 8330848871,
    staff: "Ms. Sisna P",
    prize: false,
    dept: "cse",
    reg: false,
    group: true,
    venue: "PG Lab",
    time: "10:00 AM- 3:00 PM",
  },
  {
    event_id: 13,
    name: "Trinity Showdown",
    description:
      "Battlegrounds Mobile India (BGMI): A TPP-FPP survival shooter game",
    event_type: "Mobile Gamimg",
    fee: 200,
    student1: "Dalven Jose",
    s1_phone: 6282306013,
    student2: "Amish Harinanadan",
    s2_phone: 9207882788,
    staff: "Ms.  Rahna C M",
    prize: true,
    dept: "cse",
    reg: false,
    group: true,
    venue: "S5 CSE B,Admin BLOCK, 2nd Floor",
    time: "10:00 AM - 3:00 PM",
  },
  {
    event_id: 14,
    name: "eFútbol",
    description:
      '"Join the eFootball™ 2024 mobile tournament for Co-op mode thrill.',
    event_type: "Mobile gaming",
    fee: 20,
    student1: "Thejas K",
    s1_phone: 7306790643,
    student2: " Rithika",
    s2_phone: 8304029474,
    staff: "Ms. Diya ",
    prize: true,
    dept: "cse",
    reg: false,
    group: true,
    venue: "M. Tech Classroom,Admin BLOCK, 2nd Floor",
    time: "10:30 AM",
  },
  {
    event_id: 15,
    name: "Buhahaha",
    description: "wide varieties of fun games",
    event_type: "Entertainment Games",
    fee: "FREE",
    student1: "Sayand S Anand ",
    s1_phone: 9400668391,
    student2: "Nayan Rose Mathew",
    s2_phone: 8086520743,
    staff: "Ms. Sreelakshmi M",
    prize: true,
    dept: "cse",
    reg: false,
    group: false,
    venue: "S3 CSE C,Admin BLOCK, 2nd Floor",
    time: "Full day",
  },
  {
    event_id: 16,
    name: "Mystery Digits",
    description:
      "Mystery Game features 5 chances of escalating captcha challenges and an infinite mode for endless mystery-solving fun, merging puzzles with an immersive narrative.",
    event_type: "Entertainment Games",
    fee: 'FREE',
    student1: "Ashish ",
    s1_phone: 8547413213,
    student2: "Arun Thomas",
    s2_phone: 7510667072,
    staff: "Ms. Sreelakshmi M",
    prize: true,
    dept: "cse",
    reg: false,
    group: false,
    venue: "S3 CSE B,Admin BLOCK, 2nd Floor",
    time: "Full day",
  },
  {
    event_id: 17,
    name: "Crypto Hunt",
    description:
      "Engage in a captivating journey of cryptography at ‘Learn and Crack CipherText.’ Unlock the secrets of encrypted messages, challenge your intellect, and unravel the mysteries of ciphers in this interactive event ",
    event_type: "Learn and crack cipherText ",
    fee: "FREE",
    student1: "Thomas James",
    s1_phone: 9074920960,
    student2: "Anjal K",
    s2_phone: 7510667072,
    staff: "Ms. Nisha P",
    prize: false,
    dept: "cse",
    reg: false,
    group: false,
    venue: "S3 CSE B,Admin BLOCK, 2nd Floor",
    time: "Full day",
  },
  {
    event_id: 18,
    name: "Unheard Echoes",
    description:
      "Team up in pair to solve a gripping a murder mystery. First the teams will be allotted wth points , when you hit roadblocks , you can ask for clues but points will be reduced .The first duo to unravel the mystery will be the winners. ",
    event_type: "Mobile Game",
    fee: "FREE",
    student1: "Malavika A Manoj ",
    s1_phone: 974569569,
    student2: "Navanith Vipin",
    s2_phone: 8089371542,
    staff: "Ms.  Ujwala Vijayan",
    prize: false,
    dept: "cse",
    reg: false,
    group: true,
    venue: "S3 CSE B,Admin BLOCK, 2nd Floor",
    time: "Full day",
  },
];

export default CseEventData;
